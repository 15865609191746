import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openingCash } from "../../Redux/Action/loginAction";
import { useTranslation } from "react-i18next";
import { cash, opening_cash_icon } from "../../assets";

const InputField = ({ label, placeholder, value, onChange }) => {
  const { t, i18n } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <div
        style={{ direction: 'ltr' }}
        className="flex border-2 border-solid border-gray-300 rounded-xl overflow-hidden justify-center items-start"
      >
        <span className="px-3 py-3 bg-white text-black max-md:pe-3">EGP</span>
        <input
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="p-3 bg-white rounded-xl text-left border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 w-full focus:outline-none focus:border-2px"
        />
      </div>
    </>
  );
};

const OpeningCash_Modal = ({ onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const userData = useSelector((state) => state?.login?.user?.result);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // console.log("UserData", userData);

  const inputFields = [
    { label: "Amount", placeholder: "Enter Amount" },
  ];

  const handleConfirm = async () => {
    try {
      const response = await dispatch(openingCash(userData?.session_id, inputValue));
      if (response === true) {
        onClose();
      }
    } catch (error) {
      console.error("Error", error.response.data);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleConfirm();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleConfirm]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section className="relative flex flex-col justify-center items-center px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[40px] text-zinc-800 max-md:px-5 z-40" style={{ width: "600px" }}>
        <img src={opening_cash_icon} alt="" className="mb-10" />

        <div className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Enter the Opening Cash")}</h1>
        </div>
        <div className="flex justify-center w-[100%] mt-10">
          {inputFields?.map((field, index) => (
            <InputField
              key={index}
              placeholder={t(field.placeholder)}
              value={inputValue}
              onChange={(e) => {
                let inputValue = e.target.value;
                inputValue = inputValue.replace(/ز/g, '.');

                if (inputValue?.match(/^\d*\.?\d{0,2}$/) && inputValue < 10000000) {
                  if (inputValue.length > 1 && inputValue.replace('.', '') === '0'.repeat(inputValue.replace('.', '').length)) {
                    return;
                  }
                  setInputValue(inputValue);
                }
              }}

            />
          ))}
        </div>
        <div className="flex justify-center mt-10 max-md:mt-10">
          <button
            type="submit"
            className="py-3.5 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg w-[442px] max-md:px-5"
            onClick={handleConfirm}
          >
            {t("Confirm")}
          </button>
        </div>
      </section>
    </div>
  );
};

export default OpeningCash_Modal;
