import { removeFav } from "../Action/removeFavAction";

const initialState = {
    removeFav: false,
  };
  
  const removeFavReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FOR_REMOVE':
        return {
          ...state,
          removeFav: action.payload,
        };
      default:
        return state;
    }
  };

  export default removeFavReducer;