import { URLS } from "../../API/API endpoints";
import axiosInstance from '../../AxiosInstance';
import { store } from "../store";

export const FETCH_ORDER_RECEIPT_REQUEST = 'FETCH_ORDER_RECEIPT_REQUEST';
export const FETCH_ORDER_RECEIPT_SUCCESS = 'FETCH_ORDER_RECEIPT_SUCCESS';
export const FETCH_ORDER_RECEIPT_FAILURE = 'FETCH_ORDER_RECEIPT_FAILURE';

const fetchOrderReceiptRequest = () => ({
    type: FETCH_ORDER_RECEIPT_REQUEST,
});

const fetchOrderReceiptSuccess = (order) => ({
    type: FETCH_ORDER_RECEIPT_SUCCESS,
    payload: order,
});

const fetchOrderReceiptFailure = (error) => ({
    type: FETCH_ORDER_RECEIPT_FAILURE,
    payload: error,
});

export const fetchOrderReceipt = (order_id) =>  async (dispatch) => {
        // const orderId = parseInt(order_id)
        dispatch(fetchOrderReceiptRequest());
        axiosInstance.post(URLS.RECEIPT_ORDER_DETAILS, { order_id })
            .then(response => {
                if(response?.data.result.state == "success"){
                    // dispatch(fetchOrderReceiptSuccess(response.data.result));
                // console.log("response.data.Order",response.data.result)

                    return response.data.result
                }
                // console.log("response.data.Order",response.data.Order)
            })
            .catch(error => {
                dispatch(fetchOrderReceiptFailure(error.message));
            });
    };