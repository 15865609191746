import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderReceipt } from "../../../Redux/Action/orderReceiptAction";
import OrderReceipt from "./OrderReceipt";
import { plus_icon, send_icon } from "../../../assets/";
import { clearProducts } from "../../../Redux/Action/productsAction";
import { clearCustomer } from "../../../Redux/Action/getcustomerAction";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useTranslation } from "react-i18next";
import OrderReceiptPDF from "./OrderReceiptPDF";
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";

const SESSION_STORAGE_KEY = "products";
const RETURN_PRODUCTS = "returnProductReducer";

function ReceiptOption({ option, icon }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex gap-5 py-1.5 pr-1 pl-6 mt-8 text-base text-black-400 bg-white rounded-lg max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
      <input
        type="text"
        placeholder={t(option)}
        className="flex-auto my-auto outline-none"
      />
      <img
        src={send_icon}
        alt=""
        className={`shrink-0 rounded-md aspect-[1.59] w-[93px] cursor-pointer transition-transform transform ${
          i18n.language == "ar" ? "scale-x-[-1]" : ""
        }`}
        onClick={() => console.log("Send receipt")}
      />
    </div>
  );
}

// function NewOrderButton() {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();

//   const handleNewOrderClick = () => {
//     localStorage.removeItem(SESSION_STORAGE_KEY);
//     navigate("/session");
//   };

//   return (
//     <div
//       className="flex flex-col cursor-pointer justify-center px-10 py-4 mx-2 mt-8 ms-40 bg-[#1B4597] rounded-lg border border-solid border-stone-300 max-md:px-5 h-16"
//       onClick={handleNewOrderClick}
//     >
//       <div className="flex gap-3">
//         <img
//           src={plus_icon}
//           alt="Cash in icon"
//           className="shrink-0 my-auto  aspect-square w-[18px]"
//         />
//         <div className="text-white text-xl font-bold">{t("New Order")}</div>
//       </div>
//     </div>
//   );
// }

// function FinishButton() {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();

//   // const handleNewOrderClick = () => {
//   //   localStorage.removeItem(SESSION_STORAGE_KEY);
//   //   navigate("/session");
//   // };

//   return (
//     <div
//       className="flex flex-col cursor-pointer justify-center px-10 py-4 mx-2 mt-8 ms-40 bg-white rounded-lg border border-solid border-[#1B4597] max-md:px-5 h-16"
//       // onClick={handleNewOrderClick}
//     >
//       <div className="flex gap-3">
//         <img
//           src={plus_icon}
//           alt="Cash in icon"
//           className="shrink-0 my-auto  aspect-square w-[18px]"
//         />
//         <div className="text-[#1B4597] text-xl font-bold">{t("New Order")}</div>
//       </div>
//     </div>
//   );
// }

function OrderCheckout() {
  const { t, i18n } = useTranslation();
  const [receiptData, setReceiptData] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.login);
  const store_id = user?.store_id;
  const navigate = useNavigate();
  // const { order_id } = useParams();
  const order_id = new URLSearchParams(window.location.search).get("order_id");
  const userData = useSelector((state) => state?.login?.user?.result);

  // Auto print window 

  // useEffect(() => {
  //   if (receiptData?.order[0]?.order_no) {
  //     handlePrintReceipt();
  //   }
  // }, [receiptData?.order[0]?.order_no]);


  useEffect(() => {
    const getReceiptData = async () => {
      try {
        if (order_id) {
          //   const data = await dispatch(fetchOrderReceipt(Number(order_id)));
          const response = await axiosInstance.post(
            URLS.RECEIPT_ORDER_DETAILS,
            {
              order_id: Number(order_id),
            }
          );
          if (response?.data?.result?.state == "success") {
            // console.log("response.data.Order", response?.data?.result);
            setReceiptData(response?.data?.result);
            dispatch(clearProducts());
            dispatch(clearCustomer());
          }
        } else {
          console.error("No order_id found");
        }
      } catch (error) {
        console.error("Error fetching receipt data:", error);
      }
    };

    getReceiptData();
  }, [order_id, dispatch]);

  const receiptOptions = [{ option: "WhatsApp number", icon: "" }];

  const handlePrintReceipt = () => {
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(OrderReceiptPDF(receiptData, t, i18n.language, userData));
    doc.close();

    iframe.contentWindow.focus();
    // iframe.contentWindow.print();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Normalize key input for consistent handling
      const normalizedKey =
        {
          " ": "Space", // Space key normalization
          Shift: "Shift", // Shift key normalization
          p: "ح", // Ctrl+P key normalization
        }[event.key] || event.key; // Fallback to the original key if no mapping exists

      if (event.ctrlKey && normalizedKey === "ح") {
        event.preventDefault();
        handlePrintReceipt();
      } else if (normalizedKey === "Space") {
        event.preventDefault();
        handleNewOrderClick();
      } else if (normalizedKey === "Shift") {
        event.preventDefault();
        handleFinishClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [receiptData]);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.key === "p") {
  //       event.preventDefault();
  //       handlePrintReceipt();
  //     } else if (event.key === " ") {
  //       event.preventDefault();
  //       handleNewOrderClick();
  //     }else if (event.key === "Shift") {
  //       event.preventDefault();
  //       handleFinishClick();
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [receiptData]);

  const handleNewOrderClick = () => {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    dispatch(clearReturnProducts());
    navigate("/session");
  };

  const handleFinishClick = () => {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    dispatch(clearReturnProducts());
    navigate("/dashboard");
  };

  return (
    <>
      <div className="flex">
        <div className="flex justify-center max-md:flex-col max-md:gap-0 w-[70%] ">
          <div className="mt-28 w-[60%]">
            <div className="flex flex-row justify-between ms-5 me-5 ">
              {receiptData?.order[0].customer && (
                <div className="text-lg">
                  {t("Customer Name")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {isNaN(parseFloat(receiptData?.order[0].customer)) &&
                    !/^\d{3}[-.]?\d{3}[-.]?\d{4}$/.test(
                      receiptData?.order[0].customer
                    )
                      ? receiptData?.order[0].customer
                      : "--"}
                  </span>
                </div>
              )}
              {receiptData?.order[0].customer_mobile && (
                <div className="text-lg">
                  {t("Phone Number")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {receiptData?.order[0].customer_mobile}
                  </span>
                </div>
              )}
            </div>
            {/* <div className="flex flex-row justify-between ms-5 me-5 ">
              {receiptData?.order[0].customer && (
                <div className="text-lg">
                  {t("Customer Name")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {receiptData?.order[0].customer}
                  </span>
                </div>
              )}
              {receiptData?.order[0].customer_mobile && (
                <div className="text-lg">
                  {t("Phone Number")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {receiptData?.order[0].customer_mobile}
                  </span>
                </div>
              )}
            </div> */}
            <section className="flex flex-col px-5  text-xl max-md:mt-10 max-md:max-w-full">
              <h2 className="mt-24 text-2xl font-bold tracking-wide leading-none text-zinc-800 max-md:mt-10 max-md:max-w-full">
                <span className="text-zinc-800">
                  {t("How would you like to receive the receipt?")}
                </span>
              </h2>
              <button
              className="justify-center items-center px-16 py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-[#FABF01] border border-solid border-stone-300  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                // className="justify-center items-center px-16 py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-gradient-to-b from-[#FFE17F] via-[#FABF01] to-[#FABF01] border border-gray-300 border border-solid border-stone-300  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                onClick={handlePrintReceipt}
              >
                {t("Print Receipt")}
              </button>
              <div className="flex flex-row w-full gap-8 justify-between mt-4">
                <button
                  className="justify-center items-center w-[50%] py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-[#1b4597] bg-white border border-[#1b4597] border-2 border-solid capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                  onClick={handleFinishClick}
                >
                  {t("Finish")}
                </button>

                <button
                  className="justify-center items-center w-[50%]  py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-[#1b4597] border  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                  // className="justify-center items-center w-[50%]  py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-gradient-to-b from-[#64B5FF] via-[#64B5FF] to-[#1b4597] border  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                  onClick={handleNewOrderClick}
                >
                  {t("New Order")}
                </button>
              </div>

              {/* {receiptOptions.map((option, index) => (
                                <ReceiptOption key={index} option={option.option} icon={option.icon} />
                            ))} */}
            </section>
          </div>
          {/* <NewOrderButton /> */}
        </div>
        {receiptData && <OrderReceipt receiptData={receiptData} />}
      </div>
    </>
  );
}

export default OrderCheckout;
