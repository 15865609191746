import React, { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import ShortcutsModal from "./ShortcutKeys_Modal";
import { useTranslation } from "react-i18next";

const CustomListbox = ({ userData, handleDropdownChange, selectedItem }) => {
  const { t, i18n } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShortcutsClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Listbox value={selectedItem} onChange={handleDropdownChange}>
      {({ open }) => (
        <>
            <div className="relative">

          <Listbox.Button className="flex items-center">
            {userData?.customer_details && userData?.customer_details.logo && (
              <img
                loading="lazy"
                // src={userData?.customer_details.logo}
                src={`${userData?.customer_details.logo}?time=${new Date().getTime()}`}
                alt="User Logo"
                className="shrink-0 w-9 h-9 rounded-full object-cover"
              />
            )}
            <div className="ms-2">
              {userData?.customer_details ? userData?.customer_details?.name : ""}
            </div>
          </Listbox.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Listbox.Options
              static
              className="absolute z-40 mt-2 max-h-60 w-[145px] overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Listbox.Option
                value="Shortcuts"
                className={({ active }) =>
                  `${
                    active ? "bg-gray-100 text-black" : "text-gray-900"
                  } cursor-default select-none relative py-2 pl-3 pr-9`
                }
                onClick={handleShortcutsClick}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? "font-semibold" : "font-normal"
                      } block truncate`}
                    >
                      {t("Shortcuts")}
                    </span>
                    {selected ? (
                      <span
                        className={`${
                          active ? "text-white" : "text-blue-600"
                        } absolute inset-y-0 right-0 flex items-center pr-4`}
                      >
                        {/* Checkmark icon */}
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
          {isModalOpen && (
            <ShortcutsModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            >
              {/* modal content */}
            </ShortcutsModal>
          )}
        </div>
        </>
      )}
    </Listbox>
  );
};

export default CustomListbox;