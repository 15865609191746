import React, { useEffect } from "react";
import { shop_logo } from "../../../assets";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function OrderReceipt({ receiptData }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);
  const { order } = useSelector((state) => state?.orderReceiptReducer || {});
  const userData = useSelector((state) => state?.login?.user?.result);
  // useEffect(() => {
  //     if (order) {
  //     }
  // }, []);
  // console.log("receiptData-->",receiptData)
  const renderProductLines = () => {
    return receiptData?.order[0]?.products_lines.map((item, index) => (
      <tr key={index}>
        <td className="text-center align-top">{index + 1}</td>
        <td className={i18n.language === "ar" ? "text-right" : "text-left"}>
          {item.name}
        </td>
        {/* <td className="text-right">{item.name}</td> */}
        {/* <td className="text-center">{item.qty}</td> */}
        <td className="text-center">
          {i18n.language === "ar"
            ? item.qty >= 0
              ? `${item.qty}`
              : `${Math.abs(item.qty)}-`
            : item.qty}
        </td>
        <td className="text-center">{item.price_unit}</td>
        {/* <td className="text-center">{Number(item.sub_total).toFixed(2)}</td> */}
        <td className="text-center">
          {i18n.language === "ar"
            ? Number(item.sub_total) >= 0
              ? `${Number(item.sub_total).toFixed(2)}`
              : `${Math.abs(Number(item.sub_total)).toFixed(2)}-`
            : Number(item.sub_total).toFixed(2)}
        </td>
        {/* <td className="text-center">{item.sub_total}</td> */}
      </tr>
    ));
  };

  let order_id = receiptData?.order[0]?.order_no;
  let order_idChange = order_id?.replace(/\s/g, "%20");
  let Change = 0;
  let Cash;

  //   console.log("pdatatata--->",userData?.company_name_ar )

  receiptData?.order[0]?.payment_data.forEach((item) => {
    if (item?.name == "return") {
      Change = item?.amount;
    }
    if (item?.payment_method == "Bank") {
      Change = 0;
      Cash = item?.amount;
    }
    if (item?.name == false && item?.payment_method == "Cash") {
      Cash = item?.amount;
      Change = (Cash - receiptData.order[0].order_value).toFixed(2);
    }
  });

  if (receiptData?.order[0]?.payment_data.length === 1) {
    // console.log("AAAAAAAA",receiptData?.order[0]);
    
    const payment = receiptData?.order[0]?.payment_data[0];
    if (payment?.payment_method === "Cash" && payment.amount === 0.0) {
      Change = 0.0; // Set change to 0 if payment method is Cash and amount is 0
      Cash = receiptData?.order[0]?.order_value
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        const receiptContainer = document.querySelector(".custom-scrollbar");
        if (receiptContainer) {
          if (event.key === "ArrowUp") {
            receiptContainer.scrollTop -= 40; // adjust the scroll amount as needed
          } else if (event.key === "ArrowDown") {
            receiptContainer.scrollTop += 40; // adjust the scroll amount as needed
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const hasBankPayment = receiptData?.order[0]?.payment_data.some(payment => payment.payment_method === "Bank");
const hasCashPayment = receiptData?.order[0]?.payment_data.some(payment => payment.payment_method === "Cash");

  return (
    <div className="flex flex-col text-center w-[35%] max-md:ml-0 max-md:w-full h-full">
      <div className="fixed flex flex-col grow px-2 bg-white border border-solid border-stone-300 max-md:px-3 max-md:mt-10 max-md:max-w-full h-full">
        <div className="overflow-y-auto custom-scrollbar mb-20 mt-2 px-8">
          <div className="flex text-center items-center justify-center">
            <img
              src={receiptData?.order[0]?.compan_add.logo}
              alt="Store Logo"
              width="100"
            />
          </div>
          <div className="">
            {i18n.language == "ar"
              ? userData?.company_name_ar
              : userData?.company_name}
          </div>
          <div className="">
            {receiptData?.order[0]?.compan_add?.street &&
              receiptData?.order[0]?.compan_add?.street + ", "}
            {receiptData?.order[0]?.compan_add?.street2 &&
              receiptData?.order[0]?.compan_add?.street2 + ", "}
            {receiptData?.order[0]?.compan_add?.city &&
              receiptData?.order[0]?.compan_add?.city + ", "}
            {receiptData?.order[0]?.compan_add?.state &&
              receiptData?.order[0]?.compan_add?.state + ", "}
            {receiptData?.order[0]?.compan_add?.country &&
              receiptData?.order[0]?.compan_add?.country}
          </div>
          <div className="">
            {i18n.language == "ar" ? "" : "+20 "}
            {receiptData?.order[0]?.compan_add.tel}{" "}
            {i18n.language == "ar" ? " 20+" : ""}
          </div>

          <div className="text-center font-bold py-2 text-lg">
            {t("Receipt")}
          </div>
          <div className="border-y-2 border-dashed border-black py-2">
            <div className="flex justify-between ">
              <strong>{t("Order Number:")}</strong>
              <span>{receiptData?.order[0]?.order_no}</span>
            </div>
            <div className="flex justify-between ">
              <strong>{t("Order Date:")}</strong>
              <span>{receiptData?.order[0]?.date}</span>
            </div>
            <div className="flex justify-between ">
              <strong>{t("Cashier Name:")}</strong>
              <span>{receiptData?.order[0]?.cashier}</span>
            </div>
            {receiptData?.order[0]?.customer !== false &&
            receiptData?.order[0]?.customer_mobile !== false ? (
              <>
                <div className="flex justify-between ">
                  <strong>{t("Customer Name:")}</strong>
                  <span>
                    {receiptData?.order[0]?.customer ===
                    receiptData?.order[0]?.customer_mobile
                      ? "--"
                      : receiptData?.order[0]?.customer}
                  </span>
                </div>
                <div className="flex justify-between ">
                  <strong>{t("Customer Phone No.:")}</strong>
                  <span>{receiptData?.order[0]?.customer_mobile}</span>
                </div>
              </>
            ) : null}
          </div>
          {/* <div className="border-y-2 border-dashed border-black py-2">
                    <div className="flex justify-between ">
                        <strong>{t("Order Number:")}</strong>
                        <span>{receiptData?.order[0]?.order_no}</span>
                    </div>
                    <div className="flex justify-between ">
                        <strong>{t("Order Date:")}</strong>
                        <span>{receiptData?.order[0]?.date}</span>
                    </div>
                    <div className="flex justify-between ">
                        <strong>{t("Cashier Name:")}</strong>
                        <span>{receiptData?.order[0]?.cashier}</span>
                    </div>
                     {receiptData?.order[0]?.customer !== false &&
                   isNaN(parseFloat(receiptData?.order[0]?.customer)) 
                   ? <>  
                   <div className="flex justify-between ">
                        <strong>{t("Customer Name:")}</strong>
                        <span>{receiptData?.order[0]?.customer}</span>
                    </div>
                    <div className="flex justify-between ">
                        <strong>{t("Customer Phone No.:")}</strong>
                        <span>{receiptData?.order[0]?.customer_mobile}</span>
                    </div>
                    </> : ""} 
                   
                </div> */}
          <table className="w-full mt-4 text-sm">
            <thead className="mb-2">
              <tr>
                <th className="text-center text-nowrap">{t("S.N")}</th>
                <th className="text-center px-4">{t("Product Name")}</th>
                <th className="text-right">{t("Qty")}</th>
                <th className="text-center text-nowrap px-4 ">
                  {t("Unit Price")}
                </th>{" "}
                {/* Added padding */}
                <th className="text-right">{t("Amount")}</th>
              </tr>
            </thead>
            <tbody>{renderProductLines()}</tbody>
          </table>
          {/* </div> */}
          <div className="border-t-2 border-dashed border-black flex justify-between py-2 mt-4">
            <strong>{t("Total")}</strong>
            <span>
              EGP {Number(receiptData?.order[0]?.order_value).toFixed(2)}
            </span>
            {/* <span>EGP {receiptData?.order[0]?.order_value}</span> */}
          </div>
          {/* {receiptData?.order[0]?.payment_data.length === 2 && receiptData?.order[0]?.payment_data.name === false ? ( */}
          {hasBankPayment && hasCashPayment ? (
            <div className="flex flex-col ">
              {receiptData?.order[0]?.payment_data.map((payment, index) => (
                <div className="flex justify-between py-2" key={index}>
                  <strong>{t(payment.payment_method)}</strong>
                  <span>EGP {Number(payment.amount).toFixed(2)}</span>
                </div>
              ))}
            </div>
          ) : (
            <>
            <div className="flex justify-between py-2">
            <strong>
              {t(receiptData?.order[0]?.payment_data[0]?.payment_method)}
            </strong>
            <span>EGP {Number(Cash).toFixed(2)}</span>
          </div>

            <div className="flex justify-between py-2">
              <strong>{t("Change")}</strong>
              <span>EGP {Number(Change).toFixed(2)}</span>
            </div>
          </>
          )}
          {/* <div className="flex justify-between py-2">
            <strong>
              {t(receiptData?.order[0]?.payment_data[0]?.payment_method)}
            </strong>
            <span>EGP {Number(Cash).toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <strong>{t("Change")}</strong>
            <span>EGP {Number(Change).toFixed(2)}</span>
            {/* <span>EGP {Change}</span> */}

          <div className="w-full my-4 items-center justify-center flex">
            <img
              src={`https://barcode.tec-it.com/barcode.ashx?data=${order_idChange}&code=Code39&translate-esc=on`}
              alt="Receipt Barcode"
              // className=' self-center h-20 object-fit'
              width={"90%"}
            />
          </div>

          <div className="text-center border-t-2 border-dashed border-black mt-10 text-lg py-4">
            {t("Your Technology Partner")}
            <br />
            <img
              // src="https://live-production-asset.s3.me-central-1.amazonaws.com/myginne.webp"
              src="https://images.myginne.com/myginne.webp"
              alt="myGinne Logo"
              width="150"
              className="mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderReceipt;
