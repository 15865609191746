import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";


export const UPDATE_FAV_PRODUCT = 'UPDATE_FAV_PRODUCT';



export const UpdateFav = (products) => ({
  type: UPDATE_FAV_PRODUCT,
  payload: products,
});



// import axiosInstance from "../../AxiosInstance";
// import { URLS } from "../../API/API endpoints";
// import { store } from "../store";


// export const updateFavProductRequest = () => ({
//     type: "UPDATE_FAV_PRODUCT_REQUEST",
//   });
  
//   export const updateFavProductSuccess = (updatedProduct) => ({
//     type: "UPDATE_FAV_PRODUCT_SUCCESS",
//     payload: updatedProduct,
//   });

//   export const updateFavProductFailure = (error) => ({
//     type: "UPDATE_FAV_PRODUCT_FAILURE",
//     payload: error,
//   });


// export const updateFavProduct = (product_id, store_id, isfav) => async (dispatch) => {
//     const state = store.getState();
//     dispatch(updateFavProductRequest());
  
//     try {
//       const response = await axiosInstance.post(URLS.UPDATE_FAV_PRODUCT, {
//         product_id,
//         store_id: state?.login?.user?.store_id,
//         isfav,
//       });
//       // console.log("Update favorite response:", response);
  
//       if (response?.data?.status === 200) {
//         dispatch(updateFavProductSuccess(response.data));
//       } else {
//         dispatch(updateFavProductFailure("Failed to update favorite product"));
//       }
//     } catch (error) {
//       dispatch(updateFavProductFailure(error.message));
//     }
//   };