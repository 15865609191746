import React from "react";
import checked from "../../assets/Cancel.svg";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { closePosSession } from "../../Redux/Action/loginAction";
import { useDispatch, useSelector } from "react-redux";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "1rem",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const SessionLogout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.login?.user?.result);

  const LogoutSession = () => {
    const closeSessionResponse = dispatch(
      closePosSession({
        session_id: userData?.session_id,
        diff_amount: 0,
        counted_amount: 0,
        is_accept: false,
      })
    );
    if (closeSessionResponse?.result?.status === 200) {
      // Navigate to the dashboard route
      Object.keys(localStorage).forEach((key) => {
        if (key !== "selectedLanguage") {
          localStorage.removeItem(key);
        }
      });
      window.location.reload();
    }
  };

  return (
    <div>
      <div style={styles.overlay}></div>
      <div style={styles.container}>
        <img
          src={checked} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        />
        <p style={styles.title}>{t("Session Expired")}</p>
        <p style={styles.description}>{/* Please Login */}</p>
        <div className="flex justify-center gap-5 mt-7 mb-7 ">
          {/* <Link to="/login"> */}
          <button
            type="button"
            className="w-[180px] h-[45px] bg-blue-900 text-white rounded-lg font-semibold"
            onClick={LogoutSession} // Call handleGoToDashboard onClick
            // onClick={onClose}
          >
            {t("Logout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionLogout;
