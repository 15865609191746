import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const InputField = React.forwardRef(({
    label,
    placeholder,
    changed,
    inputType,
    error,
    value,
    ...props
  }, ref) => {
    const { t, i18n } = useTranslation();
  
    return (
        <>
          <div
            className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start mt-4 ${
              inputType === "number" && i18n.language === "ar"
                ? "flex-row-reverse"
                : ""
            }`}
          >
            <input
              ref={ref} // Attach the ref to the input
              {...props}
              id="quantity-input"
              inputMode="numeric"
              placeholder={placeholder}
              style={{ direction: inputType === "number" && "ltr" }}
              onChange={(text) => changed(text)}
              value={value}
              className="p-3 rounded-xl border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px"
            />
          </div>
        </>
      );
    });

const QuantityModal = ({ initialQuantity, onConfirm, onCancel }) => {
  const [tempQuantity, setTempQuantity] = useState(initialQuantity);
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 4);

    setTempQuantity(numericValue);

    // If the value is empty, set it to 0
    if (numericValue === "") {
      setTempQuantity(0);
    } else {
      const newValue = parseInt(numericValue, 10);
      if (newValue < 10000) {
        setTempQuantity(newValue);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onConfirm(tempQuantity);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onCancel();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-50"
        style={{ width: "500px" }}
      >
        <header className="flex gap-5 text-2xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Enter Quantity")}</h1>
          <button
            type="button"
            onClick={onCancel}
            className="absolute top-4 right-3 text-zinc-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <InputField
          ref={inputRef}
          label={t("Enter quantity")}
          placeholder={t("Enter quantity")}
          changed={handleInputChange}
          value={tempQuantity}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
        />
        <div className="flex justify-between gap-5">
          <button
            onClick={onCancel}
            // className="px-4 py-2 bg-gray-500 text-white rounded-md"
            className="justify-center items-center px-12 py-3.5 mt-10 text-xl text-center text-[#7EC242] rounded-lg border-2 border-[#7EC242] border-solid capitalize whitespace-nowrap bg-white rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={() => onConfirm(tempQuantity)}
            className="justify-center items-center px-12 py-3.5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
            // className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            {t("Confirm")}
          </button>
        </div>
      </section>
    </div>
  );
};

export default QuantityModal;
