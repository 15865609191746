import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSortBy, useTable, usePagination } from "react-table";
import {
  add_icon,
  barcode_icon,
  filter_icon,
  search_icon,
  substract_icon,
  view_order_icon,
} from "../../assets";
import { Button, Modal, Select, Space } from "antd";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { GetStoreOrders } from "../../Api";
import { PosOrderLines } from "../../Api";
import { fetchStoreOrders } from "../../Redux/Action/orderAction";
import { format } from "date-fns";
import DraftOrdersTable from "./DraftOrdersTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosOrderLines } from "../../Redux/Action/orderlinesAction";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import OrderReceiptPDF from "../Order/ordercomponents/OrderReceiptPDF";
import { toast } from "react-toastify";
import { addReturnProducts } from "../../Redux/Action/returnproductAction";
import { clearProducts } from "../../Redux/Action/productsAction";
import Table from "./TableOrder";
import { setCustomer } from "../../Redux/Action/getcustomerAction";
import { setForRefund } from "../../Redux/Action/refundAction";
import ProductList, { InvoiceButton, ResendButton } from "../Order/ProductList";
import Unpaid_Modal from "../Modal/Unpaid_Modal";

const SESSION_STORAGE_KEY = "products";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div
        className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-white"
        role="status"
      >
        <span className="visually-hidden">...</span>
      </div>
    </div>
  );
};

export function OrderSearchBar() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const ref = useRef(null);

  const handleSearchChange = (searchTerm) => {
    dispatch(fetchStoreOrders({}, searchTerm));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.key === "x") {
        ref.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref]);

  return (
    <div className={`relative flex ${isRtl ? "me-8" : "ms-8"} me-8 w-[100%]`}>
      {isRtl ? (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <img src={search_icon} alt="customers_search_icon" />
        </span>
      ) : (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <img src={search_icon} alt="customers_search_icon" />
        </span>
      )}
      <input
        type="text"
        ref={ref}
        placeholder={t("Search receipt number, customer")}
        className="border p-2 ps-10 rounded w-full focus:outline-none rounded-[6px]"
        onChange={(e) => {
          const searchTerm = e.target.value;
          if (searchTerm.length >= 0) {
            handleSearchChange(searchTerm);
          }
        }}
      />
    </div>
  );
}

// function InvoiceButton({ width, onClick }) {
//   const { t, i18n } = useTranslation();
//   return (
//     <button
//       onClick={onClick}
//       className={`flex flex-col justify-center ms-10 w-5/12 py-4 items-center rounded-[5px] shadow-sm bg-[#1b4597] text-white whitespace-nowrap h-15 ${
//         width == "full" ? "w-10/12" : "w-5/12"
//       }`}
//     >
//       <div>{t("Print Receipt")}</div>
//     </button>
//   );
// }

// function ResendButton({ onClick }) {
//   const { t, i18n } = useTranslation();
//   return (
//     <button
//       onClick={onClick}
//       className="flex  justify-center ms-10 w-5/12 py-4 rounded-[5px] shadow-sm bg-[#1b4597] text-white whitespace-nowrap h-15 cursor-pointer"
//     >
//       {t("Resend Receipt")}
//     </button>
//   );
// }

// function ProductList({
//   product,
//   refundQtyForProduct,
//   updateRefundItems,
//   isChecked,
//   selectAll,
//   isProductSelect,
//   onCheckboxChange,
//   selectAllClick,
// }) {
//   const { t, i18n } = useTranslation();
//   const [quantity, setQuantity] = useState(product.available_refund_qty);
//   const [isSelected, setIsSelected] = useState(false);
//   const rateValue = product.rate;
//   const value = product.rate * quantity;
//   const [inputValue, setInputValue] = useState(product.available_refund_qty);
//   const isRTL = i18n.language === "ar";
//   const [error, setError] = useState(null);
//   const inputRef = useRef(null);
//   const [isFocused, setIsFocused] = useState(false);

//   useEffect(() => {
//     if (isSelected && isProductSelect && inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, [isSelected, isProductSelect]);

//   useEffect(() => {
//     setQuantity(product?.available_refund_qty.toFixed(2));
//     setInputValue(product?.available_refund_qty.toFixed(2));
//   }, [product?.available_refund_qty]);

//   useEffect(() => {
//     setIsSelected(isChecked);
//     if (isChecked) {
//       if (product?.uom === "KG" || product?.uom === "Kg") {
//         setQuantity(product?.available_refund_qty.toFixed(3));
//       } else {
//         setQuantity(product?.available_refund_qty);
//       }
//     } else {
//       setQuantity(refundQtyForProduct);
//     }
//   }, [isChecked]);

//   const decreaseQuantity = () => {
//     if (quantity > 1) {
//       setQuantity((prevQuantity) => {
//         const newQuantity = prevQuantity - 1;
//         updateRefundItems(product, newQuantity, isSelected);
//         return newQuantity;
//       });
//     }
//   };

//   const increaseQuantity = () => {
//     if (quantity < product.available_refund_qty) {
//       setQuantity((prevQuantity) => {
//         const newQuantity = prevQuantity + 1;
//         updateRefundItems(product, newQuantity, isSelected);
//         return newQuantity;
//       });
//     } else if (selectAll) {
//       setQuantity(product.available_refund_qty);
//       updateRefundItems(product, product.available_refund_qty, isSelected);
//     }
//   };

//   useEffect(() => {
//     if (inputRef.current && isProductSelect) {
//       inputRef.current.focus();
//     }
//   }, [isProductSelect]);

//   const handleProductClick = () => {
//     // console.log("JJJJJJJJJJJJJ");

//     if (product.available_refund_qty > 0 && value >= 0) {
//       if (product?.uom === "KG" || product?.uom === "Kg") {
//         setQuantity(product?.available_refund_qty.toFixed(3));
//         setInputValue(product?.available_refund_qty.toFixed(3));
//       } else {
//         setQuantity(product?.available_refund_qty);
//         setInputValue(product?.available_refund_qty);
//       }
//       setIsSelected((prevState) => {
//         const newIsSelected = !prevState;
//         updateRefundItems(
//           product,
//           product?.available_refund_qty,
//           newIsSelected
//         );
//         return newIsSelected;
//       });
//       if (inputRef.current) {
//         inputRef.current.focus();
//       }
//     }
//   };

//   const handleQuantityChange = (e) => {
//     let input = e.target.value;

//     input = input.replace(/ز/g, ".");
//     const regex = /^\d{0,4}(\.\d{0,3})?$/;
//     if (!regex.test(input)) {
//       setError(
//         t("Invalid quantity format. Maximum 3 decimals and total length 4.")
//       );
//       return;
//     }

//     const newQuantity = parseFloat(input);

//     if (Number(input) <= product.available_refund_qty) {
//       console.log("newQuantity", newQuantity, product.available_refund_qty);
//       setQuantity(input);
//       setInputValue(input);
//       updateRefundItems(product, newQuantity, isSelected);
//       setError(null);
//     } else if (selectAll) {
//       setQuantity(product.available_refund_qty);
//       setInputValue(product.available_refund_qty);
//       updateRefundItems(product, product.available_refund_qty, isSelected);
//       setError(null);
//     } else if (input === 0) {
//       setError(t("Quantity cannot be zero."));
//     } else {
//       setError(t("Quantity cannot exceed the ordered quantity"));
//     }
//   };

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === " " && isProductSelect) {
//         event.preventDefault();
//         handleProductClick();
//       } else if (event.altKey || event.getModifierState("AltGraph")) {
//         event.preventDefault();
//         selectAllClick();
//       } else if (event.key == "=" && isProductSelect) {
//         event.preventDefault();
//         increaseQuantity();
//       } else if (event.key == "-" && isProductSelect) {
//         event.preventDefault();
//         decreaseQuantity();
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [isProductSelect, selectAllClick]);

//   let refunded_quantity = product?.quantity - product?.available_refund_qty;

//   useEffect(() => {
//     if (isProductSelect) {
//       const productElement = document.querySelector(
//         `[data-product-id="${product.id}"]`
//       );
//       if (productElement) {
//         if (productElement.offsetTop === 0) {
//           productElement.parentNode.scrollTop = 70;
//         } else {
//           productElement.scrollIntoView({ behavior: "smooth" });
//         }
//       }
//     }
//   }, [isProductSelect, product.id]);

//   return (
//     <div
//       onClick={handleProductClick}
//       tabIndex={0}
//       onFocus={() => setIsFocused(true)}
//       onBlur={() => setIsFocused(false)}
//       className={`flex items-center gap-5 p-3 text-base border-b border-solid border-slate-200 cursor-pointer ${
//         value < 0 ? "disabled" : ""
//       } ${isProductSelect ? "bg-blue-100" : ""}`}
//       data-product-id={product.id}
//     >
//       <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-8/12">
//         <img
//           loading="lazy"
//           src={product?.image || product?.image_url}
//           alt={product?.name}
//           className="shrink-0 aspect-square w-[35px]"
//         />
//         <div className="flex flex-col">
//           <div className="my-auto font-semibold text-neutral-700 text-wrap">
//             {product.name}
//           </div>
//           <div className="my-auto text-zinc-800 text-opacity-70">
//             <div
//               style={{ direction: "ltr", textAlign: isRTL ? "right" : "left" }}
//               className="my-auto text-zinc-800 text-opacity-70"
//             >
//               {`${product?.quantity} X ${product.currency} ${rateValue}`}
//             </div>
//             {product?.available_refund_qty <= 0 && (
//               <div className="my-auto text-red-800">{t("Refunded")}</div>
//             )}
//           </div>
//           {isSelected && (quantity > 0 || product.uom !== "Units") && (
//             <>
//               <div className="my-auto">
//                 {t("QTY")}: {product.quantity}
//               </div>

//               <div className="my-auto">
//                 {t("Refunded Quantity")}: {refunded_quantity?.toFixed(2)}
//               </div>

//               <div className="my-auto text-red-800">
//                 {t("To Refund")}: {quantity} ( {product.currency}{" "}
//                 {-value.toFixed(2)} )
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <div className="flex items-center ml-auto gap-2">
//         <div className="text-center">
//           EGP {(product?.quantity * rateValue).toFixed(2)}
//         </div>
//       </div>
//       {isSelected && (quantity > 0 || product.uom !== "Units") && (
//         <div className="flex items-center ms-auto gap-2">
//           {product.uom === "KG" || product?.uom === "Kg" || product.uom === "كجم" ? (
//             <div
//               className="flex border border-solid bg-white rounded-lg w-[110px] justify-center items-center"
//               onClick={(e) => e.stopPropagation()}
//             >
//               <input
//                 type="text"
//                 value={inputValue}
//                 ref={inputRef}
//                 style={{ direction: "ltr" }}
//                 onChange={handleQuantityChange}
//                 className="px-1 py-1 text-left border-0 w-full focus:outline-none"
//                 max={product.available_refund_qty}
//                 min={0}
//                 step="0.001"
//               />
//               <span className="px-1 border-l text-black">{t("KG")}</span>
//             </div>
//           ) : (
//             <>
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   decreaseQuantity();
//                 }}
//                 disabled={quantity <= 1}
//                 className={`${
//                   quantity <= 1 ? "opacity-50 cursor-not-allowed" : ""
//                 }`}
//               >
//                 <img
//                   loading="lazy"
//                   src={substract_icon}
//                   alt="substract_icon"
//                   className="w-[40px]"
//                 />
//               </button>
//               <div className="w-8 text-center">{quantity}</div>
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   increaseQuantity();
//                 }}
//                 disabled={quantity >= product.available_refund_qty}
//               >
//                 <img
//                   loading="lazy"
//                   src={add_icon}
//                   alt="add_icon"
//                   className={`w-[35px] ${
//                     quantity >= product.available_refund_qty
//                       ? "opacity-50 cursor-not-allowed"
//                       : ""
//                   }`}
//                 />
//               </button>
//             </>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

function AllOrderButton({ enabled, onClick, showButtons }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";

  const buttonClasses = `flex cursor-pointer  ${
    isRtl ? "ms-0" : "ms-6"
  } justify-center w-[20vh] py-2 font-medium hover:font-semibold text-black border border-solid  whitespace-nowrap rounded-lg ${
    enabled && showButtons == false
      ? // ? " border-[#9dd8ff] bg-[#1b4597] text-white"
        "border-[#1b4597] bg-[#1b4597] text-white"
      : "border-stone-300 bg-white"
  }`;

  return (
    <div className={buttonClasses} onClick={onClick}>
      <div className="mt-0.5 ml-0.5">{t("Orders")}</div>
    </div>
  );
}

function RefundedOrderButton({ enabled, onClick, showButtons }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();

  const buttonClasses = `flex cursor-pointer  ${
    isRtl ? "" : ""
  } justify-center w-[31vh] py-2 font-medium hover:font-semibold text-black border border-solid  whitespace-nowrap rounded-lg ${
    enabled && showButtons == false
      ? // ? " border-[#9dd8ff] bg-[#1b4597] text-white"
        "border-[#1b4597] bg-[#1b4597] text-white"
      : "border-stone-300 bg-white"
  }`;

  return (
    <div className={buttonClasses} onClick={onClick}>
      <div className="mt-0.5 ml-0.5">{t("Refunded Orders")}</div>
    </div>
  );
}

function UnpaidOrderButton({ enabled, onClick, showButtons }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();

  const buttonClasses = `flex cursor-pointer  ${
    isRtl ? "" : ""
  } justify-center w-[26vh] py-2 font-medium hover:font-semibold text-black border border-solid  whitespace-nowrap rounded-lg ${
    enabled && showButtons == false
      ? // ? " border-[#9dd8ff] bg-[#1b4597] text-white"
        "border-[#1b4597] bg-[#1b4597] text-white"
      : "border-stone-300 bg-white"
  }`;

  return (
    <div className={buttonClasses} onClick={onClick}>
      <div className="mt-0.5 ml-0.5">{t("Unpaid Orders")}</div>
    </div>
  );
}

function ReturnOrderButton({ enabled, onClick, showButtons }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";

  const buttonClasses = `flex cursor-pointer  ${
    isRtl ? "ms-1" : "ms-8"
  } justify-center w-[25vh] py-2 font-medium hover:font-semibold text-black border border-solid  whitespace-nowrap rounded-lg ${
    showButtons == true
      ? " border-[#9dd8ff] bg-[#1b4597] text-white "
      : "border-stone-300 bg-white"
  }`;

  return (
    <div className={buttonClasses} onClick={onClick}>
      <div className="mt-0.5 ml-0.5">{t("All Orders")}</div>
    </div>
  );
}

function PaidOrderButton({ onClick }) {
  return (
    <div
      className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg"
      onClick={onClick}
    >
      <div className="mt-0.5 ml-0.5">Paid</div>
    </div>
  );
}

function RefundOrderButton({ onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex justify-center w-[20vh] h-[10vh] items-center font-semibold  hover:font-semibold text-white bg-[#F42020] border border-solid border-stone-300 whitespace-nowrap rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className=" justify-center items-center">{t("Return")}</div>
    </div>
  );
}

function DraftOrderButton({ enabled, onClick, showButtons }) {
  const { t, i18n } = useTranslation();

  const buttonClasses = `flex cursor-pointer justify-center w-[25vh] py-2 font-medium hover:font-semibold text-black border border-solid  whitespace-nowrap rounded-lg ${
    enabled
      ? // ? "border-[#9dd8ff] bg-[#1b4597] text-white"
        "border-[#1b4597] bg-[#1b4597] text-white"
      : "border-stone-300 bg-white"
  } ${showButtons == true ? "ms-48" : "ms-0"}`;

  return (
    <div className={buttonClasses} onClick={onClick}>
      <div className="mt-0.5 ml-0.5">{t("Draft Orders")}</div>
    </div>
  );
}

function OrderSkeleton() {
  return (
    <Stack spacing={1} marginTop={5}>
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      {/* <Skeleton variant="rectangular" width={210} height={60} /> */}
      {/* <Skeleton variant="rounded" width={385} height={60} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="rectangular" width={385} height={60} /> */}
    </Stack>
  );
}

function Order_Table() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const { orders = [] } = useSelector((state) => state.orders);
  const { orderLines = [] } = useSelector((state) => state.orderlinesReducer);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDraftOrdersTable, setShowDraftOrdersTable] = useState(false);
  const [showOrderTable, setShowOrderTable] = useState(true);
  const { user } = useSelector((state) => state?.login);
  const draftOrders = useSelector((state) => state.draftOrders.draftOrders);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(-1);
  const [showResendButton, setShowResendButton] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [refundItems, setRefundItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false); // Define loading state
  const [showRefundedOrders, setShowRefundedOrders] = useState(false);
  const [selectedButton, setSelectedButton] = useState("allOrders"); // Track selected button
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
  const userData = useSelector((state) => state?.login?.user?.result);
  const [showButtons, setShowButtons] = useState(true);

  const [isUnpaidModalVisible, setIsUnpaidModalVisible] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const location = useLocation();
  const forRefund = useSelector((state) => state.refundReducer.forRefund);
  const [isProductSelectionActive, setIsProductSelectionActive] =
    useState(false);

  const closeSession = useSelector(
    (state) => state?.sessionReducer?.closeSession
  );

  useEffect(() => {
    if (location.pathname === "/orders" && location.search === "") {
      setShowButtons(false);
      dispatch(setForRefund(false));
    } else if (
      location.pathname === "/orders" &&
      location.search.includes("refundorder")
    ) {
      setShowButtons(true);
    }
  }, [location]);

  useEffect(() => {
    if (selectedButton === "allOrders") {
      dispatch(fetchStoreOrders(pagination, null, ""));
    } else if (selectedButton === "refundedOrders") {
      dispatch(fetchStoreOrders(pagination, null, "refunded"));
    } else if (selectedButton === "unpaidOrders") {
      dispatch(fetchStoreOrders(pagination, null, "unpaid"));
    }
  }, [selectedButton, pagination, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        await dispatch(fetchStoreOrders(pagination));
        setLoading(false);
        setIsProductSelectionActive(true);
        setSelectedProductIndex(0);
        setSelectedOrderIndex(null);
        setDrawerOpen(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, [dispatch, pagination]);

  useEffect(() => {
    if (orders) {
      setFilteredData(orders);
      if (setSelectedButton === "refunded") {
        setFilteredData(orders);
      }
      if (setSelectedButton === "unpaidOrders") {
        setFilteredData(orders);
      }
    }
  }, [orders]);

  const lang = i18n.language;

  const handleRowClick = async (order) => {
    setLoading(true);
    setSelectAll(false);
    setRefundItems([]);
    setSelectedProducts({});
    try {
      setSelectedOrder(order);
      setDrawerOpen(true);
      await dispatch(fetchPosOrderLines(order.id, lang));
      setSelectedOrder(order);

      navigate(`/orders?orderid=${order.id}`);
      window.history.pushState({}, "", `/orders?orderid=${order.id}`);
      setShowResendButton(!!order.customer_phone);
    } catch (error) {
      console.error("Error fetching order lines:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDraftOrderClick = (draftOrder) => {
    setSelectedOrder(draftOrder);
    setDrawerOpen(false);
    setSelectAll(false);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: t("Receipt No."),
        accessor: "receipt_no",
        Cell: ({ value }) => (
          <div className="my-auto text-ellipsis">{value}</div>
        ),
      },
      {
        Header: t("Order Date"),
        accessor: "date_order",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">{value}</div>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: t("Customer"),
        accessor: "customer_name",
        Cell: ({ row }) => {
          const { customer_name, customer_phone } = row.original;
          const truncatedName =
            customer_name && customer_name?.length > 12
              ? customer_name.substring(0, 12) + "..."
              : customer_name;
          return (
            <div className="flex-auto max-md:max-w-full">
              {customer_phone ? truncatedName : "-"}{" "}
              {customer_phone && `(${customer_phone})`}
            </div>
          );
        },
      },
      {
        Header: t("No. Of Items"),
        accessor: "items",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">{value}</div>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: t("Total"),
        accessor: "order_value",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">
            {i18n.language === "ar" && value < 0 ? (
              <span> {Math.abs(value).toFixed(2)}- EGP</span>
            ) : (
              <span>{Number(value).toFixed(2)} EGP</span>
            )}
          </div>
        ),
      },
    ];

    if (!drawerOpen) {
      baseColumns.push({
        Header: t("Status"),
        accessor: "state",
        Cell: ({ row }) => {
          const { state } = row.original;

          const handleStatusClick = (e, order) => {
            e.stopPropagation();
            setSelectedOrderDetails(order);
            setIsUnpaidModalVisible(true);
          };

          if (state === "Unpaid") {
            return (
              <button
                onClick={(e) => handleStatusClick(e, row.original)} // Pass the row data
                className={`px-2 py-0 rounded-lg bg-[#F42020] text-white font-semibold`}
              >
                {t("Unpaid")}
              </button>
            );
          }

          if (state === "Paid") {
            return (
              <button
                // onClick={handleStatusClick}

                className={`px-2 py-0 rounded-lg ${
                  state === "Paid"
                    ? "bg-[#7EC242] text-white font-semibold"
                    : "bg-[#F42020] text-white font-semibold"
                }`}
              >
                {state === "Paid" ? t("Paid") : t("Unpaid")}
              </button>
            );
          } else {
            return null;
          }
        },
      });
    }
    if (!drawerOpen) {
      baseColumns.push({
        Header: " ",
        Cell: ({ row }) => (
          <button onClick={() => handleRowClick(row.original)}>
            <img
              src={view_order_icon}
              alt="view_order_icon"
              className={`w-8 h-4 transition-transform transform ${
                i18n.language === "ar" ? "scale-x-[-1]" : ""
              }`}
            />
          </button>
        ),
      });
    }

    return baseColumns;
  }, [drawerOpen, user?.currency]);

  const navigate = useNavigate();

  const handleDraftClick = () => {
    setDrawerOpen(false);
    setShowDraftOrdersTable(true);
    setShowOrderTable(false);
    setSelectAll(false);
    setSelectedButton("draftOrders");
  };

  const handleAllOrderClick = () => {
    setShowDraftOrdersTable(false);
    setShowOrderTable(true);
    setShowRefundedOrders(false);
    setSelectedButton("allOrders");
    dispatch(fetchStoreOrders(pagination, null, ""));
    // setFilteredData(orders); // Display all orders
  };

  const handleRefundedOrderClick = () => {
    setShowRefundedOrders(true);
    setShowDraftOrdersTable(false);
    setShowOrderTable(true);
    setSelectedButton("refundedOrders");
    dispatch(fetchStoreOrders(pagination, null, "refunded"));
  };

  const handleUnpaidOrderClick = () => {
    setShowRefundedOrders(false);
    setShowDraftOrdersTable(false);
    setShowOrderTable(true);
    setSelectedButton("unpaidOrders");
    dispatch(fetchStoreOrders(pagination, null, "unpaid"));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("orderid");
    if (orderId) {
      const order = orders.find(
        (order) => order.order_id === parseInt(orderId)
      );
      if (order) {
        handleRowClick(order);
      }
    }
  }, [orders]);

  const handleResetClick = async () => {
    const params = new URLSearchParams(window.location.search);
    const orderid = params.get("orderid");
    const order_id = Number(orderid);

    try {
      const response = await axiosInstance.post(URLS.SEND_CUSTOMER_SMS, {
        order_id,
      });

      if (
        response.data &&
        response.data.result &&
        response.data.result.status_code === 200
      ) {
        toast.success(t("Message sent Successfully"));
      } else {
        toast.error(t(response.data.result.status));
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("An error occurred while sending the message");
    }
  };

  const handleInvoiceClick = async () => {
    const params = new URLSearchParams(window.location.search);
    const orderid = params.get("orderid");
    const order_id = Number(orderid);
    const response = await axiosInstance.post(URLS.RECEIPT_ORDER_DETAILS, {
      order_id,
    });
    if (response) {
      handlePrintInvoice(response?.data?.result);
    }
  };

  const handlePrintInvoice = (data) => {
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(OrderReceiptPDF(data, t, i18n.language, userData));
    doc.close();

    iframe.contentWindow.focus();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    setSelectedProducts((prev) => {
      const newSelectedProducts = {};
      const updatedRefundItems = [];
      const seenProductIds = new Set();

      if (isChecked) {
        orderLines?.forEach((product) => {
          if (
            product.available_refund_qty > 0 &&
            !seenProductIds.has(product.product_id)
          ) {
            newSelectedProducts[product.product_id] = true;

            updatedRefundItems.push({
              ...product,
              id: product.product_id,
              quantity: product.available_refund_qty,
              sale_price: product.price,
            });

            seenProductIds.add(product.product_id);
          }
        });
      } else {
        Object.keys(prev).forEach((productId) => {
          delete newSelectedProducts[productId];
        });
      }
      setRefundItems(updatedRefundItems);
      // console.log("updatedRefundItems", updatedRefundItems);

      return newSelectedProducts;
    });
  };

  const handleProductCheckboxChange = (productId) => (e) => {
    const isChecked = e.target.checked;
    setSelectedProducts((prev) => {
      const newSelectedProducts = { ...prev };
      if (isChecked) {
        newSelectedProducts[productId] = true;
      } else {
        delete newSelectedProducts[productId];
      }
      if (Object.keys(newSelectedProducts).length === 0) {
        setSelectAll(false);
      } else if (
        Object.keys(newSelectedProducts).length === orderLines.length
      ) {
        setSelectAll(true);
      } else if (
        Object.keys(newSelectedProducts).length !== orderLines.length
      ) {
        setSelectAll(false);
      }
      return newSelectedProducts;
    });
  };

  useEffect(() => {
    if (Object.keys(selectedProducts).length === orderLines.length) {
      setSelectAll(true);
    } else if (Object.keys(selectedProducts).length === 0) {
      setSelectAll(false);
    } else if (Object.keys(selectedProducts).length !== orderLines.length) {
      setSelectAll(false);
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (selectAll) {
      const newSelectedProducts = {};
      orderLines.forEach((product) => {
        if (product.available_refund_qty > 0) {
          newSelectedProducts[product.product_id] = true;
        }
      });
      setSelectedProducts(newSelectedProducts);
    } else {
    }
  }, [selectAll]);

  const updateRefundItems = (product, quantity, isSelected) => {
    // console.log(
    // `Updating refund items for product ${product.id} with quantity ${quantity} and isSelected ${isSelected}`
    // );

    const updatedRefundItems = [...refundItems];

    const existingProductIndex = updatedRefundItems.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex !== -1) {
      updatedRefundItems.splice(existingProductIndex, 1);
    }

    if (isSelected) {
      updatedRefundItems.push({
        ...product,
        quantity,
        sale_price: product.rate,
      });
    }

    setRefundItems(updatedRefundItems);
  };

  useEffect(() => {
    const availableRefundProducts = orderLines.filter(
      (product) => product.available_refund_qty > 0
    );
    if (refundItems.length != availableRefundProducts.length) {
      setSelectAll(false);
    }
    if (refundItems.length === availableRefundProducts.length) {
      setSelectAll(true);
    }
    if (refundItems.length === 0) {
      setSelectAll(false);
    }
  }, [refundItems]);

  const handleReturnClick = async () => {
    dispatch(clearProducts());

    if (refundItems.length === 0) {
      toast.error(t("Please select a product for return."));
      return;
    }

    setLoading(true);

    if (selectedOrder) {
      const order_id = selectedOrder.id;

      const uniqueRefundItems = [
        ...new Map(refundItems.map((item) => [item.id, item])).values(),
      ];

      const filteredRefundItems = uniqueRefundItems.filter(
        (item) => item.quantity > 0
      );

      const productsWithOrderId = filteredRefundItems.map((product) => ({
        ...product,
        order_id,
      }));

      dispatch(setCustomer(selectedOrder.customer_phone));
      dispatch(addReturnProducts(productsWithOrderId));
      navigate(`/session?refundorder=${selectedOrder.id}`);
    }
  };

  const areAllProductsRefundedOrNegative = () => {
    return orderLines.every((product) => {
      const productValue = product.available_refund_qty;
      return productValue <= 0;
    });
  };

  useEffect(() => {
    if (closeSession) {
      // console.log("CHALLL GYAAAAA");
      setIsProductSelectionActive(true);
      setSelectedProductIndex(0);
      setSelectedOrderIndex(null);
      setDrawerOpen(false);
    }
  }, [closeSession]);

  const handleKeyDown = useCallback(
    (e) => {
      if (closeSession) {
        return;
      }
      const key = e.key.toLowerCase();
      const normalizedKey =
        {
          "~": "z",
          "ِ": "a",
          "؛": "p",
          "ٌ": "r",
          ء: "x",
          "َ": "q",
          "ُ": "e",
          escape: "escape",
        }[key] || key;

      if (e.shiftKey && normalizedKey === "z") {
        handleDraftClick();
      } else if (e.shiftKey && normalizedKey === "a") {
        handleAllOrderClick();
      } else if (e.shiftKey && normalizedKey === "e") {
        setIsProductSelectionActive(true);
        setSelectedProductIndex(0);
        setSelectedOrderIndex(null);
        setDrawerOpen(false);
      } else if (normalizedKey === "escape") {
        e.preventDefault();
        setDrawerOpen(false);
      } else if (e.shiftKey && normalizedKey === "q") {
        handleRefundedOrderClick();
      } else if (
        normalizedKey === (i18n.language === "ar" ? "arrowleft" : "arrowright")
      ) {
        setIsProductSelectionActive(true);
        setSelectedProductIndex(0);
        setSelectedOrderIndex(null);
      } else if (
        normalizedKey === (i18n.language === "ar" ? "arrowright" : "arrowleft")
      ) {
        setIsProductSelectionActive(false);
        setSelectedOrderIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setSelectedProductIndex(null);
      }
      if (isProductSelectionActive) {
        if (normalizedKey === "arrowup") {
          e.preventDefault();
          if (selectedProductIndex > 0) {
            setSelectedProductIndex((prevIndex) => prevIndex - 1);
          }
        } else if (normalizedKey === "arrowdown") {
          e.preventDefault();
          if (selectedProductIndex < orderLines.length - 1) {
            setSelectedProductIndex((prevIndex) => prevIndex + 1);
          }
        }
      } else {
        if (showOrderTable && filteredData.length > 0) {
          e.preventDefault();
          if (normalizedKey === "arrowup") {
            setSelectedOrderIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          } else if (normalizedKey === "arrowdown") {
            e.preventDefault();
            setSelectedOrderIndex((prevIndex) =>
              Math.min(prevIndex + 1, filteredData.length - 1)
            );
          } else if (normalizedKey === "enter" && selectedOrderIndex >= 0) {
            const order = filteredData[selectedOrderIndex];
            handleRowClick(order);
          } else if (e.altKey || e.getModifierState("AltGraph")) {
            e.preventDefault();
            handleSelectAll({ target: { checked: !selectAll } });
          } else if (drawerOpen) {
            if (e.shiftKey && normalizedKey === "p") {
              handleInvoiceClick();
            } else if (
              e.shiftKey &&
              normalizedKey === "r" &&
              selectedOrder &&
              selectedOrder.customer_phone &&
              showResendButton
            ) {
              e.preventDefault();
              handleResetClick();
            }
          }
        }
      }
      if (drawerOpen) {
        if (normalizedKey === "enter" && isProductSelectionActive === true) {
          e.preventDefault();
          if (selectedOrder.state === "Unpaid") {
            setIsUnpaidModalVisible(true);
            setSelectedOrderDetails(selectedOrder);
            return; // Do nothing if the order is done
          }
          handleReturnClick();
        } else if (e.shiftKey && normalizedKey === "l") {
          setIsProductSelectionActive(false);
        }
      }
    },
    [
      showOrderTable,
      filteredData,
      selectedOrderIndex,
      selectedOrder,
      showResendButton,
      handleRowClick,
      handleInvoiceClick,
      handleResetClick,
      handleDraftClick,
      handleAllOrderClick,
      handleSelectAll,
      selectAll,
      orderLines,
      isProductSelectionActive,
    ]
  );

  const [eKeyPressed, setEKeyPressed] = useState(
    localStorage.getItem("eKeyPressed")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setEKeyPressed(localStorage.getItem("eKeyPressed"));
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (eKeyPressed !== "true") {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, eKeyPressed]);

  // const handlePaymentSuccess = () => {

  //   handleAllOrderClick();

  // };

  const handlePaymentSuccess = () => {
    // Call the function to handle all orders
    handleAllOrderClick();
    setDrawerOpen(false);
    //  handleRowClick();

    // const params = new URLSearchParams(window.location.search);
    // const orderId = params.get("orderid");
    // console.log("ORDERID",orderId);
    
    // if (orderId) {
    //   const order = orders.find(
    //     (order) => order.order_id === orderId
        
    //   );
    //   console.log("order",order);

    //   setSelectedOrder(order);
    //   dispatch(fetchPosOrderLines(orderId, lang));
      // Now, check if the orderId is still available
    // }
  };

  return (
    <>
      {isUnpaidModalVisible && (
        <Unpaid_Modal
          onClose={() => setIsUnpaidModalVisible(false)}
          title="Order Details"
          orderDetails={selectedOrderDetails}
          onPaymentSuccess={handlePaymentSuccess}
        ></Unpaid_Modal>
      )}
      <div className="flex">
        <div
          className={`flex-grow transition-all duration-300 ${
            drawerOpen ? " me-[33%]" : "w-full"
          }`}
        >
          <div className="bg-[#EEEEEE]">
            <div className="flex flex-row gap-4 bg-[#EEEEEE]">
              <div className={`${isRtl ? "ms-20" : "ms-12"} bg-[#EEEEEE]`}>
                <OrderSearchBar
                // ref={searchInputRef}
                />
              </div>
              {showButtons == false ? (
                <>
                  <AllOrderButton
                    // enabled={showOrderTable}
                    enabled={selectedButton === "allOrders"}
                    onClick={handleAllOrderClick}
                    showButtons={false}
                  />

                  <RefundedOrderButton
                    enabled={selectedButton === "refundedOrders"}
                    onClick={handleRefundedOrderClick}
                    showButtons={false}
                  />

                  <DraftOrderButton
                    enabled={
                      showDraftOrdersTable && selectedButton === "draftOrders"
                    }
                    onClick={handleDraftClick}
                    showButtons={false}
                  />

                  <UnpaidOrderButton
                    enabled={selectedButton === "unpaidOrders"}
                    onClick={handleUnpaidOrderClick}
                    showButtons={false}
                  />
                </>
              ) : null}

              {showButtons ? (
                <>
                  <ReturnOrderButton showButtons={showButtons} />
                </>
              ) : null}
            </div>
          </div>

          {showDraftOrdersTable && draftOrders && draftOrders.length === 0 && (
            <div className="flex flex-col justify-center mr-20 ml-20 mt-8 text-sm bg-white text-left rounded-[15px] overflow-hidden">
              <p className="text-center py-4 text-lg font-semibold text-zinc-800">
                {t("No Draft Orders")}
              </p>
            </div>
          )}
          {showOrderTable && filteredData && filteredData.length === 0 && (
            <div className="flex flex-col justify-center mr-20 ml-20 mt-8 text-sm bg-white text-left rounded-[15px] overflow-hidden">
              <p className="text-center py-4 text-lg font-semibold text-zinc-800">
                {t("No Orders")}
              </p>
            </div>
          )}

          {showOrderTable && filteredData && filteredData.length > 0 && (
            <div className="flex flex-col justify-center mx-20 mt-7 mb-4 pb-2 text-sm bg-white text-left rounded-[15px] overflow-hidden">
              <Table
                columns={columns}
                data={filteredData}
                pagination={pagination}
                isLoadingData={isLoadingData}
                setPagination={setPagination}
                onRowClick={(val) => {
                  navigate(`/orders?orderid=${val?.id}`);
                  handleRowClick(val);
                }}
                selectedRowIndex={selectedOrderIndex}
              />
            </div>
          )}
        </div>
      </div>
      {showDraftOrdersTable && draftOrders && draftOrders.length > 0 ? (
        <div className="flex flex-col justify-center mr-20 ml-20 mt-8 text-sm text-left ">
          <DraftOrdersTable
            draftOrders={draftOrders}
            onRowClick={handleDraftOrderClick}
          />
        </div>
      ) : (
        <>
          {drawerOpen && (
            <div
              className={`absolute flex-auto top-40 lg:top-20 end-0 h-[calc(140vh-4rem)] bg-white shadow-lg z-10 py-5 transition-transform transform ${
                drawerOpen ? "translate-x-0 w-[29%]" : "translate-x-full w-0"
              }`}
            >
              <div className="flex justify-between px-5 items-center">
                <h2 className="text-xl font-bold text-blue-900">
                  {t("Receipt No.")} {selectedOrder?.receipt_no}
                </h2>
                <button
                  onClick={() => setDrawerOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-6 px-5 text-base">
                {selectedOrder?.customer_name && (
                  <p>
                    <strong>{t("Customer Name :")}</strong>{" "}
                    {selectedOrder?.customer_name}
                  </p>
                )}
                {selectedOrder?.customer_phone && (
                  <p>
                    <strong>{t("Customer Phone No.:")}</strong>{" "}
                    {selectedOrder?.customer_phone}
                  </p>
                )}
                <p>
                  <strong>{t("Total Amount :")}</strong>{" "}
                  {selectedOrder?.order_value}
                </p>
                <div className="flex flex-row justify-between">
                  <div>
                    <p>
                      <strong>{t("Number of Items :")}</strong>{" "}
                      {selectedOrder?.items}
                    </p>
                    <p>
                      <strong>{t("Payment Mode :")}</strong>{" "}
                      {t(selectedOrder?.payment)}
                    </p>
                    {selectedOrder?.state === "Unpaid" &&
                    !areAllProductsRefundedOrNegative() ? (
                      <p>
                        <strong>{t("Payment Status :")}</strong> {t("Unpaid")}
                      </p>
                    ) : (
                      !areAllProductsRefundedOrNegative() &&
                      selectedOrder.state === "Paid" && (
                        <p>
                          <strong>{t("Payment Status :")}</strong> {t("Paid")}
                        </p>
                      )
                    )}
                  </div>
                  {selectedOrder?.state === "Unpaid" &&
                  !areAllProductsRefundedOrNegative() ? (
                    <button
                      // onClick={() => setIsUnpaidModalVisible(true)}
                      onClick={() => {
                        setSelectedOrderDetails(selectedOrder); // Set the selected order details
                        setIsUnpaidModalVisible(true);
                      }}
                      className="flex justify-center items-center w-[26vh] py-2 h-[10vh] font-semibold hover:font-semibold text-white bg-[#F42020] border border-solid border-stone-300 whitespace-nowrap rounded-lg cursor-pointer"
                    >
                      <div className="justify-center items-center">
                        {t("Collect Payment")}
                      </div>
                    </button>
                  ) : (
                    !areAllProductsRefundedOrNegative() &&
                    selectedOrder.state === "Paid" && (
                      <RefundOrderButton onClick={handleReturnClick} />
                    )
                  )}
                  {/* {!areAllProductsRefundedOrNegative() && selectedOrder.state === "paid" && (
                    <RefundOrderButton onClick={handleReturnClick} />
                  )} */}
                </div>
                {!areAllProductsRefundedOrNegative() &&
                  selectedOrder?.state != "Unpaid" && (
                    <div className="flex items-center mb-2 mt-5">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectAll}
                        className="w-5 h-5 accent-[#1B4597]"
                      />
                      <label className="ms-2">{t("Select All")}</label>
                    </div>
                  )}
              </div>
              {loading ? (
                <div className="px-5">
                  <OrderSkeleton />
                </div>
              ) : (
                <div className="mt-6 px-5 overflow-y-auto h-[68%] custom-scrollbar">
                  {orderLines?.map((product, index) => {
                    const refundQtyForProduct =
                      refundItems?.find(
                        (prod) => prod.product_id === product.product_id
                      )?.qty || 0;
                    return (
                      <ProductList
                        key={index}
                        selectedOrder={selectedOrder}
                        refundQtyForProduct={refundQtyForProduct}
                        selectAllClick={() =>
                          handleSelectAll({ target: { checked: !selectAll } })
                        }
                        product={{
                          id: product.product_id,
                          name: product.name,
                          image: product.image_url,
                          size:
                            product.variants[0]?.Size ||
                            product.variants[0]?.Type ||
                            "",
                          quantity: product.qty,
                          rate: product.price,
                          currency: product.currency,
                          uom: product.uom,
                          available_refund_qty: product?.available_refund_qty,
                        }}
                        isChecked={
                          selectedProducts[product.product_id] || false
                        }
                        onCheckboxChange={handleProductCheckboxChange(
                          product.product_id
                        )}
                        updateRefundItems={updateRefundItems}
                        isProductSelect={index === selectedProductIndex}
                      />
                    );
                  })}
                  <div className="flex flex-row w-full absolute bottom-2 end-2 justify-center items-center mt-10 font-semibold text-lg">
                    {showResendButton && orderLines[0]?.uniq_code_pos && (
                      <ResendButton onClick={handleResetClick} />
                    )}
                    <InvoiceButton
                      width={selectedOrder?.customer_phone ? "half" : "full"}
                      onClick={handleInvoiceClick}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Order_Table;
