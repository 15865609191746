import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 45,
    backdropFilter: "blur(5px)",
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 49,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "0.5rem",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1rem",
    color: "black",
    marginBottom: "30px",
  },
};

const ConfirmRemember = ({ isOpen, onConfirm, onClose }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        onConfirm(); 
      } else if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onConfirm, onClose]); 

  if (!isOpen) return null; // Ensure the modal doesn't render if not open

  return (
    <div>
      <div style={styles.overlay}></div>
      <div style={styles.container}>
        <p style={styles.title}>{t("Are you sure you want to save your credentials ?")}</p>
        <div className="flex justify-around gap-5 mt-7 px-6 mb-2">
          <button
            type="button"
            className="w-[150px] h-[45px] bg-white border border-[#7EC242] text-[#7EC242] rounded-lg font-semibold"
            // onClick={onClose} // Close modal on "No"
            onClick={() => {
                onClose(); // Call onConfirm and close modal
                // onClose(); // Close modal after confirmation
              }}
          >
            {t('No')}
          </button>
          <button
            type="button"
            className="w-[150px] h-[45px] bg-[#7EC242] text-white rounded-lg font-semibold"
            onClick={() => {
              onConfirm(); // Call onConfirm and close modal
            //   onClose(); // Close modal after confirmation
            }}
          >
            {t('Yes')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRemember;