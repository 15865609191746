import React, { useState, useRef, useEffect } from "react";
import {
  return_icon,
  plus_icon,
  star_icon,
  right_scroll,
  left_scroll,
} from "../../assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductBox } from "./sessioncomponents/ProductBox";
import ProductSearchBar from "./sessioncomponents/ProductSearchBar";
import ProductGrid from "./sessioncomponents/ProductGrid";
import { URLS } from "../../API/API endpoints";
import { useSelector } from "react-redux";
import axiosInstance from "../../AxiosInstance";
import OpeningCash_Modal from "../Modal/OpeningCash_Modal";
import {
  getcategorydata,
  sessionstatesave,
} from "../../Redux/Action/sessionidentifierAction";
import { useDispatch } from "react-redux";
// import { debounce } from 'lodash';
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { addProducts, clearProducts } from "../../Redux/Action/productsAction";
import BarcodeReader from "react-barcode-reader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addDraftOrder } from "../../Redux/Action/draftOrdersAction";
import { clearCustomer } from "../../Redux/Action/getcustomerAction";
import { setForRefund } from "../../Redux/Action/refundAction";
import { removeFav } from "../../Redux/Action/removeFavAction";

const NewOrderButton = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="flex justify-center w-[30vh] py-2 font-semibold bg-[#1b4597] text-white whitespace-nowrap rounded-lg cursor-pointer"
      // className="flex justify-center w-[30vh] py-2 font-semibold bg-gradient-to-b from-blue-800 via-blue-900 to-blue-900 text-white whitespace-nowrap rounded-lg cursor-pointer"
    >
      <img
        src={plus_icon}
        alt="plus_icon"
        className=" mt-1 shrink-0 self-start w-5 aspect-[0.96]"
      />
      <div className="mt-0.5 ml-1">{t("New Order")}</div>
    </div>
  );
};

const CategoryButton = ({ children, active, highlighted, onClick }) => (
  <button
    className={`flex-none justify-center px-5 py-2 mt-2 border border-solid border-stone-300 rounded-lg font-bold shadow-sm mr-2 ${
      active
        ? "text-white bg-[#76CFEE]"
        : highlighted
        ? "text-gray-600 bg-[#E0F7FA]"
        : "text-gray-600 bg-white"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

function CategorySkeleton() {
  return (
    <Stack direction="row" spacing={1} sx={{ marginTop: "0.5rem" }}>
      <Skeleton variant="rounded" width={60} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
    </Stack>
  );
}

function ProductGridSkeleton() {
  const rows = 3;
  const columns = 6;

  return (
    <div>
      {[...Array(rows)].map((_, rowIndex) => (
        <Stack
          key={rowIndex}
          direction="row"
          spacing={1}
          sx={{ marginTop: rowIndex === 0 ? "1rem" : "1rem" }}
        >
          {[...Array(columns)].map((_, colIndex) => (
            <Stack
              key={colIndex}
              spacing={1}
              sx={{ marginLeft: "10px !important" }}
            >
              <Skeleton variant="rounded" width={165} height={152} />
              <Skeleton
                variant="text"
                width={120}
                sx={{ fontSize: "0.5rem", marginTop: "2rem" }}
              />
              <Skeleton
                variant="text"
                width={100}
                sx={{ fontSize: "0.5rem", marginTop: "2rem" }}
              />
            </Stack>
          ))}
        </Stack>
      ))}
    </div>
  );
}

const DRAFT_ORDER_KEY = "draftOrder";

const ProductCo = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { draftOrder } = location.state || {};
  const { store_id } = useSelector((state) => state?.login?.user);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState(
    draftOrder?.products || []
  );
  const [isScrollAtStart, setIsScrollAtStart] = useState(true);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const [categories, setcategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const { OpenCashModal } = useSelector((state) => state?.sessionIdentify);
  const carouselRef = useRef(null);
  const searchInputRef = useRef(null);
  const [search, setsearch] = useState("");
  const [categoryid, setcategoryid] = useState(null);
  const [isfav, setisfav] = useState(false);
  const [isCartCleared, setIsCartCleared] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.user?.result);
  const localProducts = useSelector((state) => state.products.products);
  const { selectedCustomer } = useSelector((state) => state.getcustomer);
  const [isCategorySelectionActive, setIsCategorySelectionActive] =
    useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [isScannerActive, setIsScannerActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userSelectedIndex, setUserSelectedIndex] = useState(null);
  const returnProducts = useSelector(
    (state) => state.returnProductReducer.products
  );

  const [highlightedCategoryIndex, setHighlightedCategoryIndex] =
    useState(null);

  const [focusedProductIndex, setFocusedProductIndex] = useState(null);
  const [isProductSelectionActive, setIsProductSelectionActive] =
    useState(false);

  const updateFav = useSelector((state) => state.removeFavReducer.removeFav);  
  
  useEffect(() => {
    if (updateFav == true){
      handleFavoriteCategoryClick();
    }
  }, [updateFav]);

  useEffect(() => {
    dashboardFunctions();
  }, []);

  useEffect(() => {
    if (isScannerActive) {
      setUserSelectedIndex(null);
    }
  }, [isScannerActive]);

  const dashboardFunctions = async () => {
    const isLangCalled = await setSystyemLang();
    if (isLangCalled) {
      setProductsLoading(true);

      getcategories();
      handleFavoriteCategoryClick()
      // getProducts("", "TEXT", categoryid);
    }
  };

  useEffect(() => {
    if (!isCartCleared && location.state && location.state.draftOrder) {
      dispatch(addProducts(location.state.draftOrder.products));
    }
  }, [location.state, isCartCleared]);

  useEffect(() => {
    if (!isCartCleared) {
    }
  }, [draftOrder, isCartCleared]);

  const getcategories = async () => {
    setCategoriesLoading(true);
    const response = await axiosInstance.post(URLS.GET_CATEGORIES, {
      company_id: userData?.company_id,
    });
    if (response?.data?.result[0]?.status_code === 200) {
      setcategories(response?.data?.result[0]?.response);
      setActiveIndex(0);
      setcategoryid("fav");
    }
    setCategoriesLoading(false);
  };

  const setSystyemLang = async () => {
    const response = await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
      code: i18n.language,
    });
    if (response?.data?.result?.status_code === 200) {
      return true;
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const getProducts = async (val, searchBy, cat_id, isFavorite = false) => {
    if (searchBy === "BARCODE") {
      setProductsLoading(false);
    }
    let payload;

    const existingProductIndex = localProducts?.findIndex(
      (item) => item.barcode === val
    );

    if (existingProductIndex !== -1 && searchBy === "BARCODE") {
      const product = localProducts[existingProductIndex];
      handleSelectProduct(product, val);
      return;
    }
    if (searchBy === "TEXT") {
      payload = {
        company_id: userData.company_id,
        parent_category_id: cat_id || 0,
        product_name: val,
        is_favorite: isFavorite,
        barcode: "",
        limit: isFavorite ? 1000 : 50, 
        offset: 0,
        lang: i18n.language === "ar" ? "ar_001" : "en_US",
      };
    } else {
      payload = {
        company_id: userData.company_id,
        parent_category_id: cat_id || 0,
        product_name: "",
        is_favourite: isFavorite,
        barcode: val,
        limit: isFavorite ? 1000 : 50, 
        offset: 0,
        lang: i18n.language === "ar" ? "ar_001" : "en_US",
      };
    }
    if (val === "" || val?.length > 2) {

     
      
      const response = await axiosInstance.post(
        URLS.GET_CATEGORY_PROD,
        payload
      );
      // console.log("Response Data: ", response?.data?.result[0]?.response);

      if (searchBy === "BARCODE") {
        const result = response?.data?.result[0];
        if (result?.status_code === 200 && result?.response) {
          handleSelectProduct(result.response, val);
        } else {
          toast.error(t("Product Not Found"));
        }
      } else {
        setProductsLoading(true);
        if (response?.data?.result[0]?.response?.length > 0) {
          setProducts(response.data.result[0].response);
        } else {
          setProducts([]);
        }
      }
    } else {
      setProducts([]);
    }

    setProductsLoading(false);
    setIsScannerActive(false);
  };
  //     console.log("NNNNNNNN, ",response?.data?.result[0]?.response);
  //     if (response?.data?.result[0]?.response?.length > 0) {
  //       if (searchBy === "BARCODE") {
  //         // setProductsLoading(true);
  //         handleSelectProduct(response?.data?.result[0]?.response, val);
  //       } else {
  //         setProductsLoading(true);
  //         setProducts(response?.data?.result[0]?.response);
  //       }
  //     } else {
  //       setProductsLoading(false);
  //       if (searchBy != "BARCODE") {
  //         setProducts([]);
  //       }
  //       if (searchBy === "BARCODE") {
  //         toast.error(t("Product Not Found"));
  //       }
  //     }
  //   } else {
  //     setProducts([]);
  //   }
  //   setProductsLoading(false);
  //   setIsScannerActive(false);
  // };

  const debouncedGetProducts = debounce((val, searchBy, cat_id) => {
    setProductsLoading(true);

    setActiveIndex(0);
    setcategoryid(0);
    getProducts(val, searchBy, 0);
  }, 500);

  const handleNewOrderClick = () => {
    if (localProducts && Object.keys(localProducts).length > 0) {
      const now = new Date();
      const draftOrder = {
        id: Date.now(),
        customer: selectedCustomer ? selectedCustomer : undefined,
        items: localProducts,
        date: now.toISOString().split("T")[0],
        time: now.toTimeString().split(" ")[0],
        total: Object.values(localProducts).reduce(
          (total, item) => total + item.sale_price * item.quantity,
          0
        ),
      };
      dispatch(addDraftOrder(draftOrder));
      console.log("CHLAAAA");
      dispatch(clearProducts());
      setFocusedProductIndex(false);
      // setIsCategorySelectionActive(true);
      // setHighlightedCategoryIndex(1);
      setIsProductSelectionActive(false);
      dispatch(clearCustomer());
    }
  };

  const handleSelectProduct = (product, text) => {
    if (!Array.isArray(localProducts)) {
      console.error("localProducts is not an array:", localProducts);
      return;
    }

    if (product.weight && (!text || text.length !== 13)) {
      toast.error(t("Please check the barcode"));
      return;
    }

    const updatedProducts = [...localProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === product.prod_id
    );

    if (existingProductIndex !== -1) {
      if (product.weight) {
        const weightInKg = product.weight / 1000;
        const updatedQuantity =
          updatedProducts[existingProductIndex].quantity + weightInKg;
        updatedProducts[existingProductIndex].quantity = parseFloat(
          updatedQuantity.toFixed(3)
        );
      } else {
        updatedProducts[existingProductIndex].quantity += 1;
      }
      setSelectedProducts(updatedProducts[existingProductIndex]);
    } else {
      let quantity = 1;
      if (product.weight) {
        quantity = parseFloat((product.weight / 1000).toFixed(3));
      }
      updatedProducts.push({ ...product, quantity });
      setSelectedProducts(updatedProducts[updatedProducts.length - 1]);
    }

    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
    setUserSelectedIndex(
      updatedProducts.findIndex((p) => p.prod_id === product.prod_id)
    );
  };

  // const handleSelectProduct = (product, text) => {
  //   if (!Array.isArray(localProducts)) {
  //     console.error("localProducts is not an array:", localProducts);
  //     return;
  //   }

  //   if (
  //     (product.weight) &&
  //     (!text || text.length !== 13)
  //   ) {
  //     toast.error(t("Please check the barcode"));
  //     return;
  //   }

  //   const updatedProducts = [...localProducts];
  //   const existingProductIndex = updatedProducts.findIndex(
  //     (p) => p.prod_id === product.prod_id
  //   );
  //   if (existingProductIndex !== -1) {
  //     updatedProducts[existingProductIndex].quantity += 1;
  //     setSelectedProducts(updatedProducts[existingProductIndex]);
  //   } else {
  //     updatedProducts.push({ ...product, quantity: 1 });
  //     setSelectedProducts(updatedProducts[updatedProducts.length - 1]);
  //   }
  //   dispatch(addProducts(updatedProducts));
  //   setSelectedProducts(updatedProducts);
  //   setUserSelectedIndex(
  //     updatedProducts.findIndex((p) => p.prod_id === product.prod_id)
  //   );
  // };

  // useEffect(() => {
  //   if (selectedProducts.length > 0) {
  //     setSelectedProducts(selectedProducts[0]);
  //   }
  // }, [selectedProducts]);

  const handleRemoveProduct = (product) => {
    if (!Array.isArray(localProducts)) {
      console.error("localProducts is not an array:", localProducts);
      return;
    }
    const updatedProducts = localProducts.filter(
      (p) => p.prod_id !== product.prod_id
    );
    const previousProduct = updatedProducts[updatedProducts.length - 1];
    const previousProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === previousProduct.prod_id
    );
    setSelectedProducts(
      previousProductIndex !== -1 ? updatedProducts[previousProductIndex] : null
    );
    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
    setUserSelectedIndex(
      updatedProducts.length > 0 ? updatedProducts.length - 1 : null
    );
  };

  const handleUpdateQuantity = (productId, quantity) => {
    const updatedProducts = [...localProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.prod_id === productId
    );
    if (existingProductIndex !== -1) {
      updatedProducts[existingProductIndex].quantity = Math.max(quantity, 0);
    }
    dispatch(addProducts(updatedProducts));
    setSelectedProducts(updatedProducts);
  };

  const handleCategoryClick = async (index, id, apiCall) => {
    setProductsLoading(true);
    setActiveIndex(index);
    setcategoryid(id);
    setSelectedCategoryIndex(index);
    setIsCategorySelectionActive(null);
    setHighlightedCategoryIndex(0);
    // setIsCategorySelectionActive(false);
    // setFocusedProductIndex(null);
    if (apiCall) {
      getProducts("", "TEXT", id);
    }
  };

  const handleFavoriteCategoryClick = () => {
    // setCurrentCategory("fav");
    setcategoryid("fav"); // Set categoryid to "fav" when favorite is clicked
    // setFavoriteActive(true);
    // setSelectedCategory("fav");
    // if (isLocal) {
    //   getlocalProducts("", "TEXT", "fav", true);
    // } else {
      getProducts("", "TEXT", 0, true);
      dispatch(removeFav(false))

    // }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setIsScrollAtStart(scrollLeft === 0);
    setIsScrollAtEnd(scrollLeft + clientWidth === scrollWidth);
  };

  const scrollToPrevItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollToNextItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const [isOrderButtonVisible, setIsOrderButtonVisible] = useState(false);

  const keyMappings = {
    N: ["N", "آ"],
    S: ["S", "ٍ"],
    ArrowRight: ["ArrowRight", "ArrowRight"],
    ArrowLeft: ["ArrowLeft", "ArrowLeft"],
    Backspace: ["Backspace", "ي"],
    Delete: ["Delete", "حذف"],
    Enter: ["Enter", "إدخال"],
    " ": [" ", " "],
    ArrowDown: ["ArrowDown", "تحت"],
  };

  const isKeyMatch = (key, targets) => targets.includes(key);

  const handleKeyDown = (e) => {
    const key = e.key;

    if (e.shiftKey && isKeyMatch(key, keyMappings.N)) {
      e.preventDefault();
      handleNewOrderClick();
      searchInputRef.current.blur();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNewOrderClick]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key;

      if (e.ctrlKey && isKeyMatch(key, keyMappings.Enter)) {
        e.preventDefault();
        setIsCategorySelectionActive(false);
        setIsProductSelectionActive(false);
        setUserSelectedIndex(false);
        searchInputRef.current.blur();
      }

      if (e.shiftKey && isKeyMatch(key, keyMappings.ArrowRight)) {
        e.preventDefault();
        setFocusedProductIndex(false);
        setIsCategorySelectionActive(true);
        setHighlightedCategoryIndex(0);
        setIsProductSelectionActive(false);
        searchInputRef.current.blur();
      }

      if (e.shiftKey && isKeyMatch(key, keyMappings.Backspace)) {
        e.preventDefault();
        dispatch(clearProducts());
        setFocusedProductIndex(false);
        setIsProductSelectionActive(false);
      }

      if (isKeyMatch(key, keyMappings.Delete)) {
        e.preventDefault();
        setFocusedProductIndex(false);
        setIsProductSelectionActive(false);
      }

      if (isCategorySelectionActive) {
        if (isKeyMatch(key, keyMappings.ArrowRight)) {
          e.preventDefault();
          setFocusedProductIndex(false);

          if (i18n.language === "ar") {
            setHighlightedCategoryIndex((prev) => Math.max(prev - 1, -1));
          } else {
            setHighlightedCategoryIndex((prev) =>
              Math.min(prev + 1, categories.length)
            );
          }

          const carousel = carouselRef.current;
          const newHighlightedCategoryIndex =
            i18n.language === "ar"
              ? highlightedCategoryIndex - 1
              : highlightedCategoryIndex + 1;
          const selectedCategoryElement =
            carousel.children[newHighlightedCategoryIndex - 1];

          if (selectedCategoryElement) {
            const scrollPosition = carousel.scrollLeft;
            const categoryWidth = selectedCategoryElement.offsetWidth;

            if (i18n.language === "ar") {
              if (
                scrollPosition + carousel.offsetWidth <
                categoryWidth * newHighlightedCategoryIndex
              ) {
                carousel.scrollBy({
                  left: categoryWidth * 4,
                  behavior: "smooth",
                });
              }
            } else {
              if (
                scrollPosition + carousel.offsetWidth <
                categoryWidth * newHighlightedCategoryIndex
              ) {
                carousel.scrollBy({
                  left: categoryWidth * 4,
                  behavior: "smooth",
                });
              }
            }
          }
        }

        if (isKeyMatch(key, keyMappings.ArrowLeft)) {
          e.preventDefault();
          setFocusedProductIndex(false);

          if (i18n.language === "ar") {
            setHighlightedCategoryIndex((prev) =>
              Math.min(prev + 1, categories.length)
            );
          } else {
            setHighlightedCategoryIndex((prev) => Math.max(prev - 1, -1));
          }

          const carousel = carouselRef.current;
          const newHighlightedCategoryIndex =
            i18n.language === "ar"
              ? highlightedCategoryIndex + 1
              : highlightedCategoryIndex - 1;
          const selectedCategoryElement =
            carousel.children[newHighlightedCategoryIndex - 1];

          if (selectedCategoryElement) {
            const scrollPosition = carousel.scrollLeft;
            const categoryWidth = selectedCategoryElement.offsetWidth;

            if (i18n.language === "ar") {
              if (
                scrollPosition <
                categoryWidth * (newHighlightedCategoryIndex - 1)
              ) {
                carousel.scrollBy({
                  left: -categoryWidth * 1,
                  behavior: "smooth",
                });
              }
            } else {
              if (
                scrollPosition <
                categoryWidth * (newHighlightedCategoryIndex - 1)
              ) {
                carousel.scrollBy({
                  left: -categoryWidth * 2,
                  behavior: "smooth",
                });
              }
            }
          }
        }

        if (isKeyMatch(key, keyMappings.Enter)) {
          e.preventDefault();
          // const selectedCategory =
          //   highlightedCategoryIndex === 0
          //     ? { category_id: 0 }
          //     : categories[highlightedCategoryIndex - 1];

          if (highlightedCategoryIndex === -1) {
            // Call the function to handle the favorite category click
            handleFavoriteCategoryClick();
          } else {
            const selectedCategory =
              highlightedCategoryIndex === 0
                ? { category_id: 0 } // Assuming 0 is for the "All" category
                : categories[highlightedCategoryIndex - 1];
                
          handleCategoryClick(
            highlightedCategoryIndex,
            selectedCategory.category_id,
            true
          );
          setIsCategorySelectionActive(highlightedCategoryIndex);
          setHighlightedCategoryIndex(highlightedCategoryIndex);
        }
      }
      }

      if (e.shiftKey && isKeyMatch(key, keyMappings.ArrowDown)) {
        e.preventDefault();
        setIsCategorySelectionActive(false);
        setIsProductSelectionActive(true);
        setFocusedProductIndex(0);
        searchInputRef.current.blur();
      }

      if (isProductSelectionActive) {
        if (isKeyMatch(key, keyMappings.ArrowRight)) {
          e.preventDefault();
          if (i18n.language === "ar") {
            setFocusedProductIndex((prev) => Math.max(prev - 1, 0));
          } else {
            setFocusedProductIndex((prev) =>
              Math.min(prev + 1, products.length - 1)
            );
          }
        }

        if (isKeyMatch(key, keyMappings.ArrowLeft)) {
          e.preventDefault();
          if (i18n.language === "ar") {
            setFocusedProductIndex((prev) =>
              Math.min(prev + 1, products.length - 1)
            );
          } else {
            setFocusedProductIndex((prev) => Math.max(prev - 1, 0));
          }
        }

        if (isKeyMatch(key, keyMappings[" "])) {
          e.preventDefault();
          if (focusedProductIndex !== null) {
            handleSelectProduct(products[focusedProductIndex]);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    isCategorySelectionActive,
    highlightedCategoryIndex,
    categories,
    isProductSelectionActive,
    focusedProductIndex,
    products,
    isScannerActive,
    handleSelectProduct,
  ]);

  useEffect(() => {
    const keyMap = {
      S: ["S", "ٍ"],
    };

    const normalizeKey = (key) => {
      const normalizedKeys = {
        "ٍ": "S",
      };
      return normalizedKeys[key] || key;
    };

    const handleKeyDown = (e) => {
      const normalizedKey = normalizeKey(e.key);

      if (e.shiftKey && keyMap.S.includes(normalizedKey)) {
        e.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
        setIsProductSelectionActive(false);
        setIsCategorySelectionActive(false);
        setFocusedProductIndex(null);
        setHighlightedCategoryIndex(0);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const keyMap = {
      S: ["S", "ٍ"],
    };

    const normalizeKey = (key) => {
      const normalizedKeys = {
        "ٍ": "S",
      };
      return normalizedKeys[key] || key;
    };

    const handleKeyDown = (e) => {
      const normalizedKey = normalizeKey(e.key);

      if (e.shiftKey && keyMap.S.includes(normalizedKey)) {
        e.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
        setIsProductSelectionActive(false);
        setIsCategorySelectionActive(false);
        setFocusedProductIndex(null);
        setHighlightedCategoryIndex(0);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      {OpenCashModal && (
        <OpeningCash_Modal
          onClose={() => {
            dispatch(sessionstatesave(false));
          }}
        />
      )}

      <div className="grid grid-cols-3 bg-[#EEEEEE] mt-2 gap-0 me-5 ">
        <ProductBox
          draftOrder={draftOrder}
          products={localProducts}
          userSelectedIndex={userSelectedIndex}
          setUserSelectedIndex={setUserSelectedIndex}
          onRemoveProduct={handleRemoveProduct}
          onUpdateQuantity={handleUpdateQuantity}
          isScannerActive={isScannerActive}
          returnProducts={returnProducts}
          // onClearCart={handleClearCart}
        />

        <div className="flex col-span-2 justify-start ps-4">
          <div className="flex flex-col ps-6 w-full max-w-[100%]">
            <div className="flex flex-row justify-start gap-5">
              <div style={{ width: isOrderButtonVisible ? "80%" : "100%" }}>
                <ProductSearchBar
                  // value={search}
                  ref={searchInputRef}
                  onSearch={(e) => {
                    const value = e.target.value;
                    // handleCategoryClick(0, 0)
                    // setsearch(value);
                    setIsProductSelectionActive(false);
                    setFocusedProductIndex(null);
                    debouncedGetProducts(value, "TEXT", categoryid);
                  }}
                  id="searchBar"
                />
              </div>
              {Object.keys(localProducts).length > 0 && (
                <NewOrderButton onClick={handleNewOrderClick} />
              )}
            </div>
            <div className="flex flex-row items-center ">
              {!isScrollAtStart && (
                <button
                  className={`mt-2 ${
                    i18n.language === "ar"
                      ? "bg-gradient-to-r from-[#EEEEEE] to-[#7EC242]"
                      : "bg-gradient-to-l from-[#EEEEEE] to-[#7EC242]"
                  } font-bold focus:outline-none hover:${
                    i18n.language === "ar"
                      ? "bg-gradient-to-r"
                      : "bg-gradient-to-l"
                  } rounded-lg p-2`}
                  onClick={() =>
                    i18n.language === "ar"
                      ? scrollToNextItem()
                      : scrollToPrevItem()
                  }
                >
                  <img
                    src={i18n.language === "ar" ? right_scroll : left_scroll}
                    className="h-6 w-12"
                    alt="Scroll Icon"
                  />
                </button>
              )}
              <div
                className="flex overflow-x-auto scrollbar-hidden"
                ref={carouselRef}
                onScroll={handleScroll}
              >
                {categoriesLoading ? (
                  <CategorySkeleton />
                ) : (
                  <>
                  <CategoryButton
                      active={categoryid === "fav"}
                      highlighted={highlightedCategoryIndex === -1}
                      onClick={handleFavoriteCategoryClick}
                    >
                      {/* {t("Favourite")} */}
                      <img
                        className="h-5 mb-0.5 w-auto justify-center items-center"
                        src={star_icon}
                        alt="Favourite"
                      />
                    </CategoryButton>
                    <CategoryButton
                      active={categoryid === 0}
                      highlighted={highlightedCategoryIndex === 0}
                      onClick={() => handleCategoryClick(0, 0, true)}
                    >
                      {t("All")}
                    </CategoryButton>
                    {categories?.map((category, index) => (
                      <CategoryButton
                        key={index}
                        active={categoryid === category?.category_id}
                        highlighted={highlightedCategoryIndex === index + 1}
                        onClick={() =>
                          handleCategoryClick(
                            index + 1,
                            category?.category_id,
                            true
                          )
                        }
                      >
                        {/* {category.name} */}
                        {i18n.language === "ar"
                          ? category.name_ar
                          : category.name}
                      </CategoryButton>
                    ))}
                  </>
                )}
              </div>
              {!isScrollAtEnd && (
                <button
                  className={`mt-2 ${
                    i18n.language === "ar"
                      ? "bg-gradient-to-l"
                      : "bg-gradient-to-r"
                  } from-[#EEEEEE] to-[#7EC242] rounded-lg p-2`}
                  onClick={() =>
                    i18n.language === "ar"
                      ? scrollToPrevItem()
                      : scrollToNextItem()
                  }
                >
                  <img
                    src={i18n.language === "ar" ? left_scroll : right_scroll}
                    className="h-6 w-12"
                    alt="Scroll Icon"
                  />
                </button>
              )}
            </div>
            {productsLoading ? (
              <ProductGridSkeleton />
            ) : (
              <>
                <ProductGrid
                  onSelectProduct={handleSelectProduct}
                  products={products}
                  focusedProductIndex={focusedProductIndex}
                  productsLoading={productsLoading}
                />
              </>
            )}
          </div>
        </div>
        <BarcodeReader
          onScan={(data) => {
            // setIsScannerActive(true);
            // setFocusedProductIndex(null);
            handleCategoryClick(0, 0, true);
            setsearch("");
            // console.log("barcode called===>", data)
            getProducts(data, "BARCODE", 0);
          }}
          onError={(err) => {
            console.error(err);
          }}
          facingMode="environment"
          // className="absolute inset-y-0 right-0 mt-2 mr-4 pointer-events-none cursor-pointer z-10"
        />
      </div>
    </>
  );
};

export default ProductCo;
